:root{
    --theme-font: 'Inter', sans-serif;
    --primary-green: #0d874a;
    --secondary-green: #44a831;
    --light-cream: #f9faf5;
    --sub-bg: #f5f5f5;
    --heading-color: #0e0e0e;
    --paragraph: #303030;
    --span: #888888;
    --border: #ebebeb;
    --dark-green: #3A8F2A;
}
::selection{
    background-color: var(--primary-green);
    color: var(--bs-white);
}
h1, h2, h3, h4, h5, h5{
    color: var(--heading-color);
}
h1{
    font-size: 70px;
}
h2{
    font-size: 42px;
}
p{
    line-height: 30px;
    color: var(--paragraph);
}
@mixin transition($x...) {
    -webkit-transition: $x;
    -moz-transition: $x;
    -ms-transition: $x;
    -o-transition: $x;
    transition: $x;
}
.smooth {
    @include transition(all 400ms ease-in-out 0s);
}
.theme-btn{
    background-color: var(--secondary-green);
    border-radius: 5px;
    padding: 17px 30px;
    color: var(--bs-white);
    border: 2px var(--secondary-green) solid;
    svg{
        margin-left: 10px;
    }
    span, svg{
        position: relative;
        z-index: 1;
        transition: all 400ms ease-in-out 0s;
        -o-transition: all 400ms ease-in-out 0s;
        -ms-transition: all 400ms ease-in-out 0s;
        -moz-transition: all 400ms ease-in-out 0s;
        -webkit-transition: all 400ms ease-in-out 0s;
    }
}
.outline-theme-btn{
    background-color: transparent;
    color: var(--secondary-green);
    transition: all 400ms ease-in-out 0s;
    -o-transition: all 400ms ease-in-out 0s;
    -ms-transition: all 400ms ease-in-out 0s;
    -moz-transition: all 400ms ease-in-out 0s;
    -webkit-transition: all 400ms ease-in-out 0s;
    &::after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 0;
        background-color: var(--secondary-green);
        transition: all 400ms ease-in-out 0s;
        -o-transition: all 400ms ease-in-out 0s;
        -ms-transition: all 400ms ease-in-out 0s;
        -moz-transition: all 400ms ease-in-out 0s;
        -webkit-transition: all 400ms ease-in-out 0s;
    }
    &:hover, &:focus{
        border-color: var(--secondary-green);
        color: var(--bs-white);
        &::after{
            width: 100%;
        }
    }
}
.custom-container{
    max-width: 1500px;
}
body{
    font-family: var(--theme-font);
}
// Header
header{
    background-color: var(--dark-green);
    .navbar-toggle {
        display: none;
    }
    .enquiry-icon{
        width: 50px;
        height: 50px;
        border: 2px var(--bs-white) solid;
        svg{
            font-size: 20px;
        }
    }
    .enfocus-logo-col{
        a {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
        }
        img{
            max-width: 180px;
        }
    }
    .enfocus-logo-col, .enquiry-col{
        max-width: 330px;
        background-color: var(--secondary-green);
    }
    #enfocus-nav{
        .nav-item {
            .nav-link{
                padding: 28px 30px;
                font-weight: 600;
                font-size: 15px;
            }
        }
    }
}

// Slider
.enfocus-hero{
    padding: 70px 0px;
    background-color: var(--light-cream);
    .slide-img{
        width: 500px;
        .slide-feature-img{
            height: 720px;
            img{
                object-fit: cover;
                height: 100%;
            }
        }
    }
   .slide-text{
        padding-right: 90px;
        max-width: calc(100% - 500px);
        h5{
            color: var(--secondary-green);
            font-size: 20px;
        }
    }
    .slide-action{
        width: 250px;
    }
    .slide-paragraph{
        width: calc(100% - 250px);
    }
    .slide-bottom{
        margin-top: 60px;
    }
    .slide-outline-text{
        transform: rotate(-90deg);
        top: 287px;
        left: 168px;
        h1{
            font-weight: 900;
            color: var(--bs-white);
            -webkit-text-fill-color: rgba(255,255,255,0.45); /* Will override color (regardless of order) */
            -webkit-text-stroke-width: 2px;
            -webkit-text-stroke-color: var(--bs-white);
            font-size: 90px;
        }
    }
    .slick-slide{
        z-index: -1;
        &.slick-active{
            z-index: 1;
            .slide-text{
                animation-name: fadeUp;
                animation-duration: 1.5s;
                opacity: 1;
            }
            .slide-img-card{
                animation-name: slideLeft;
                animation-duration: 1.5s;
                opacity: 1;
            }
        }
        &:nth-child(even){
            &.slick-active{
                .slide-text{
                    padding-right: 0 !important;
                    padding-left: 90px;
                    animation-name: fadeDown;
                    animation-duration: 1.5s;
                    opacity: 1;
                    order: 2;
                }
                .slide-img-card{
                    animation-name: slideRight;
                    animation-duration: 1.5s;
                    opacity: 1;
                    order: 1;
                }
            }
        }
    }
}

@keyframes slideLeft{
    0%{
        opacity: 0;
        -webkit-transform: translateX(500px);
        transform: translateX(500px);
    }
    100%{
        opacity: 1;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@keyframes slideRight{
    0%{
        opacity: 0;
        -webkit-transform: translateX(-500px);
        transform: translateX(-500px);
    }
    100%{
        opacity: 1;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

// Global Section Heading + Padding
.section-padding{
    padding: 95px 0;
}
.enfocus-heading{
    span{
        color: var(--secondary-green);
        font-size: 18px;
        img{
            margin-right: 8px;
        }
    }
}

// Home About
.section-para{
    margin: 35px 0 0;
}
.section-action{
    margin: 30px 0 0;
}
.feature-points{
    ul{
        li{
            background-image: url("../images/green-check.svg");
            background-repeat: no-repeat;
            background-position: 0 center;
            text-align: left;
            background-size: 16px;
            padding: 7px 0;
            span{
                padding-left: 25px;
            }
        }
    }
}

// Know Enfocus
.know-enfocus{
    background-color: var(--light-cream);
}
.feature-card{
    height: 565px;
    padding: 40px 35px;
    border: 2px var(--span) solid;
    border-radius: 30px 0 30px 0;
    .feature-points{
        ul{
            padding-left: 35px !important;
            li{
                list-style-type: disc;
                background-image: none;
                padding-bottom: 10px;
                border-bottom: 1px solid var(--border);
                &:last-child {
                    border-bottom: 0;
                }
                span {
                    padding-left: 0;
                }
            }
        }
    }
    .feature-icon{
        width: 85px;
        height: 85px;
        background-color: var(--secondary-green);
        @extend .smooth;
        img{
            max-width: 45px;
            @extend .smooth;
        }
    }
    &::after{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background-color: var(--secondary-green);
        width: 0;
        @extend .smooth;
    }
    &:hover, &:focus{
        border-color: var(--secondary-green);
        .feature-icon{
            background-color: var(--bs-white);
            img{
                filter: contrast(0%) brightness(50%);
            }
        }
        .feature-body{
            h3, p{
                color: var(--bs-white);
            }
        }
        .feature-points {
            ul {
                li {
                    filter: brightness(7);
                    span {
                        color: var(--bs-white);
                    }
                }
            }
        }
        cursor: pointer;
        &::after{
            width: 100%;
        }
    }
}

// Highlishts
.highlights-card{
    background-color: var(--sub-bg);
    border-radius: 15px;
    .high-card-img{
        width: 100%;
        max-width: 650px;
    }
    .high-card-body{
        width: calc(100% - 650px);
        padding: 30px 40px 30px 70px;
    }
}
.feature-highlights{
    gap: 15px;
    margin: 35px 0 0;
}
.highlight-card-box{
    width: calc(33.333% - 15px);
    h6{
        line-height: 25px;
    }
}
.highlight-box-icon{
    width: 50px;
    height: 50px;
    background-color: var(--secondary-green);
    img{
        max-width: 28px;
    }
}

// Footer
.enfocus-newsletter{    
    background-image: url(../images/eco-friendly-background.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 50px 0;
    .enfocus-social{
        a{
            width: 60px;
            height: 60px;
            border: 2px var(--bs-white) solid;
            font-size: 22px;
            color: var(--bs-white);
            &:not(:last-child){
                margin-right: 15px;
            }
            &:hover, &:focus{
                background-color: var(--bs-white);
                color: var(--primary-green);
            }
        }
    }
    .form-control{
        padding: 22px 65px;
        border: 0;
        &:focus{
            box-shadow: none;
            outline: 0;
            
        }
    }
    form{
        svg{
            width: 20px;
            margin-left: 25px;
        }
    }
    .btn{
        svg{
            color: var(--secondary-green);
            font-size: 30px;
        }
        padding-left: 45px;
        padding-right: 45px;
        border-radius: 0.375rem;
        margin-left: 15px;
    }
}
.main-footer{
    padding: 70px 0;
    background-color: var(--light-cream);
}
.footer-copyright{
    background-color: var(--primary-green);
    p, a{
        margin: 0;
        color: var(--bs-white);
        text-decoration: none;
    }
}
.quick-links{
    width: 50%;
    li{
        width: 100%;
        position: relative;
        @extend .smooth;
        &::before{
            left: 0;
            top: 19px;
            content: "";
            width: 0px;
            position: absolute;
            border: 1px dashed var(--primary-green);
            opacity: 0;
            @extend .smooth;
        }
        a{
            color: var(--paragraph);
            text-decoration: none;
            display: flex;
            padding: 8px 0;
            @extend .smooth;
        }
        &:hover{
            padding-left: 20px;
            &::before{
                width: 15px;
                opacity: 1;
            }
            a{
                color: var(--primary-green);
            }
        }
    }
}

// about us

.inner-banner {
    padding: 100px 0;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../images/about-img.jpg);
    &::before {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.40);
    }
    .breadcrumb-wrapper {
        position: relative;
        .about-heading {
            h2 {
                color: var(--bs-white);
            }
        }
        .breadcrumb {
            .breadcrumb-item {
                color: #ececec;
                &::before {
                    color: #ececec; 
                }
                a {
                    color: var(--bs-white);
                    text-decoration: none;
                }
            }
        }
    }
}

.inner-page-wrapper {
    padding: 90px 0;
}

.enquiry-form-wrapper {
    padding: 60px 0;
    background-color: var(--light-cream);
    .enquiry-heading {
        margin-bottom: 50px;
        h2 {
            color: var(--secondary-green);
        }
    }
}

.custom-input {
    font-size: 14px;
    color: var(--span);
    padding: 15px 22px;
    border: 1px solid #e2e2e2;
    &:focus {
        outline: 0;
        box-shadow: none;
        border: 1px solid var(--primary-green);
    }
}

.contact-btn {
    padding: 15px 50px;
    &:hover, &:focus {
        color: var(--bs-white);
        background-color: #188704;
    }
}

.contact-info {
    padding: 30px;
    height: 170px;
    border-radius: 6px;
    border: 1px solid #e2e2e2;
    .address-info {
        margin-top: 20px;
        .icon-wrapper {
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            border-radius: 50px;
            justify-content: center;
            border: 2px solid var(--secondary-green);
            .location-icon {
                font-size: 25px;
                color: var(--secondary-green);
            }
        }
        p {
            width: calc(100% - 56px);
            display: flex;
            align-items: center;
            margin: 0;
        }
    }
}

.project-img {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    transition: transform .2s; /* Animation */
    img {
        width: 100%;
        transition: 0.5s all ease-in-out;
    }
    &:hover {
        img {
            transform: scale(1.2);
        }
    } 
}