@media (max-width: 1560px){
    .custom-container{
        max-width: 1300px;
    }
    header{
        .enfocus-logo-col, .enquiry-col{
            max-width: 275px;
        }
    }
    .enfocus-hero{
        .slide-action{
            max-width: 230px;
        }
        .slide-paragraph{
            width: calc(100% - 230px);
        }
    }
    .long-arrow{
        img{
            max-width: 100px;
        }
    }
    .enfocus-hero{
        .slide-text{
            padding-right: 50px;
        }
        .slick-slide{
            &:nth-child(even){
                &.slick-active{
                    .slide-text{
                        padding-left: 50px;
                    }
                }
            }
        }
    }
    .know-enfocus{
        .gx-5{
            --bs-gutter-x: 2rem;
        }
    }
    .feature-card{
        padding: 35px 20px;
    }
}
@media (max-width: 1360px){
    h1 {
        font-size: 65px;
    }
    h2{
        font-size: 39px;
    }
    .custom-container{
        max-width: 1140px;
    }
    header{
        .enfocus-logo-col, .enquiry-col{
            max-width: 240px;
        }
        #enfocus-nav{
            .nav-item{
                .nav-link{
                    padding: 28px 25px;
                }
            }
        }
    }
    .enfocus-hero{
        .slide-img{
            width: 430px;
        }
        .slide-text {
            padding-right: 35px;
            max-width: calc(100% - 430px);
        }
        .slick-slide{
            &:nth-child(even){
                &.slick-active{
                    .slide-text{
                        padding-left: 35px;
                    }
                }
            }
        }
        .slide-outline-text{
            left: 110px;
        }
    }
    .know-enfocus{
        .gx-5{
            --bs-gutter-x: 1.5rem;
        }
    }
    .highlights-card{
        .high-card-img{
            max-width: 510px;
        }
        .high-card-body{
            width: calc(100% - 510px);
            padding: 30px 25px 30px 40px;
        }
    }
    .feature-highlights{
        gap: 8px;
        margin: 20px 0 0;
    }
    .highlight-card-box{
        width: calc(33.333% - 6px);
    }
}

@media (max-width: 1199px){
    h1{
        font-size: 57px;
    }
    h2{
        font-size: 35px;
    }
    h3{
        font-size: calc(1.1rem + .6vw);
    }
    p{
        font-size: 15px;
        line-height: 28px;
    }
    .custom-container {
        max-width: 960px;
    }
    header{
        .enfocus-logo-col, .enquiry-col{
            max-width: 205px;
        }
        .enquiry-col{
            padding-left: 0;
        }
        .enfocus-logo-col{
            img{
                max-width: 150px;
            }
        }
        .enquiry-icon{
            width: 40px;
            height: 40px;
            font-size: 17px;
        }
        #enfocus-nav{
            .nav-item{
                .nav-link{
                    padding: 25px 18px;
                    font-size: 14px;
                }
            }
        }
    }
    .enfocus-hero{
        .slide-bottom{
            margin-top: 35px;
            flex-wrap: wrap;
        }
        .slide-text {
            h5{
                font-size: 18px;
            }
        }
        .slide-action, .slide-paragraph{
            width: 100%;
            max-width: 100%;
            order: 2;
        }
        .slide-paragraph{
            margin-bottom: 35px;
            order: 1;
        }
        .long-arrow{
            display: none;
        }
        .slide-img {
            width: 370px;
            .slide-feature-img{
                height: 570px;
            }
        }
        .slide-text{
            max-width: calc(100% - 370px);
        }
        .slide-outline-text{
            top: 227px;
            h1{
                font-size: 75px;
            }
        }
    }
    .section-para{
        margin: 18px 0 0;
    }
    .feature-points{
        ul{
            li{
                font-size: 15px;
                background-size: 15px;
                padding: 4px 0;
            }
        }
    }
    .section-action {
        margin: 18px 0 0;
    }
    .theme-btn{
        padding: 14px 25px;
    }
    .section-padding {
        padding: 65px 0;
    }
    .highlights-card{
        .high-card{
            flex-wrap: wrap;
        }
        .high-card-img{
            max-width: 100%;
            height: 400px;
            overflow: hidden;
            img{
                height: 100%;
                object-fit: cover;
            }
        }
        .high-card-body{
            width: 100%;
            padding: 30px 45px;
        }
    }
    .feature-highlights {
        gap: 25px;
    }
    .highlight-card-box {
        width: calc(33.333% - 25px);
    }
    .feature-card{
        .feature-icon{
            width: 70px;
            height: 70px;
            img{
                max-width: 36px;
            }
        }
    }
    .main-footer {
        padding: 45px 0;
    }
    .footer-links{
        flex-wrap: wrap;
        .quick-links{
            width: 100%;
        }
        a{
            font-size: 15px;
        }
    }
    .enfocus-newsletter{
        .btn{
            padding-left: 30px;
            padding-right: 30px;
            min-width: 155px;
        }
    }
}
@media (max-width: 991px){
    h1{
        font-size: 50px;
    }
    h2{
        font-size: 30px;
    }
    .custom-container {
        max-width: 720px;
    }
    .enfocus-hero{
        padding: 50px 0px;
        .slider-wrap{
            flex-wrap: wrap;
        }
        .slide-text{
            max-width: 100%;
            padding: 0 !important;
            margin: 0 0 35px;
        }
        .slide-img {
            width: 100%;
        }
        .slick-slide{
            &:nth-child(even){
                &.slick-active{
                    .slide-text{
                        order: 1;
                    }
                    .slide-img{
                        order: 2;
                    }
                }
            }
        }
    }
    .section-padding {
        padding: 50px 0;
    }
    .about-enfocus{
        .row{
            flex-direction: column-reverse;
        }
        .home-about-img{
            margin: 35px 0 0;
        }
    }
    
    .contact-info {
        margin-bottom: 30px;
    }

    header{
        padding: 15px 0;
        background-color: var(--secondary-green);
        #enfocus-nav {
            ul {
                align-items: start !important;
                .nav-item {
                    width: 100%;
                    &:hover {
                        background-color: var(--primary-green);
                    }
                    .nav-link {
                        padding: 15px 18px;
                    }
                }
            }
        }
        .enfocus-logo-col{
            img{
                max-width: 166px;
            }
        }
        .enfocus-logo-col {
            display: flex;
        }
        .enfocus-logo-col, .enquiry-col{
            max-width: 50%;
            background-color: var(--secondary-green);
        }
        .navbar-toggle {
            padding: 6px 13px;
            border-radius: 6px;
            margin-right: 20px;
            display: block;
            border: 2px solid var(--bs-white);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 22px;
            background-image: url(../images/menu.svg);
            .navbar-toggler-icon {
                width: 16px;
                height: 16px;
            }
        }

        .header-right  {
            justify-content: end;
        }

        .navbar-collapse  {
            right: 0;
            top: 0;
            display: block !important;
            position: fixed;
            height: 100vh;
            width: 225px;
            transform: translateX(225px) !important;
            background-color: var(--primary-green);
            transition: all ease-in 0.3s;
            z-index: 99;
            &.show {
                transform: translateX(0) !important;
            }
        }
    }

    .home-about-img {
        margin-bottom: 30px;
    }

    .inner-page-wrapper {
        padding: 60px 0;
    }
}

@media (max-width: 767px) {
    .feature-card {
        margin-bottom: 30px;
    }
}